import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from '@/router';
import { ref, } from 'vue';
import KeyCloak from 'keycloak-js';
import VueSession from 'vue-session';
import { i18n, setLanguage, loadLanguageAsync, } from '@/plugins/i18n';
import Authentication from 'lib-security/src/lib-components/security.vue';
import store from '@/store';
import { Buffer } from 'buffer';
import '@/styles/styles.scss';

Vue.use(VueSession);
Vue.config.productionTip = false;

// App initialization options

const localRealm = "gemsys-dev.geminys.com";

var realm = window.location.hostname;
var origin = window.location.origin;
if (realm === 'localhost') {
  realm = localRealm;
  origin = "https://" + realm;
}
var clientId = "hermes_" + realm;

var initOptions = {
  "onLoad": "login-required",
	"checkLoginIframe": false,
	"url": origin + "/auth",
	"realm": realm,
	"clientId": clientId,
};

// Client data

var clientData = ref({});

function base64ToBlob( str, type = "application/octet-stream" ) {
  const binStr = Buffer.from( str, 'base64' );
  return new Blob( [ binStr ], { type: type } );
}

async function loadClient() {
  try {
    //var startTime = performance.now()
    var promises = [];
    const clientManagementRs = await import('lib-client-management-rs/src/lib-components/client-management-rs.vue');
    promises.push(clientManagementRs.default.methods.getClient(null, initOptions.realm).then((response => {
      clientData.value = response.data;
    })));
    var base64Array = [];
    promises.push(clientManagementRs.default.methods.getClientLogoFiles(null, initOptions.realm).then((response) => {
      base64Array = response.data;
    }));
    await Promise.all(promises);

    var documentLanguageIdsForUserInCompany = [];

    try {
      var tokenParsed = Authentication.methods.getTokenParsed();
    } catch (error) {
      console.log(error);
    }
    
    promises = [];
    var companiesWithLimitedDocumentLanguagesForUsersProperty = null;
    promises.push(
      clientManagementRs.default.methods
        .getProperty('document-management', 'companies-with-limited-document-languages-for-users')
        .then((response) => {
          companiesWithLimitedDocumentLanguagesForUsersProperty = response.data;
        })
    );
    var companiesWithLimitedDocumentLanguagesForUsersPropertyValue = null;
    promises.push(
      clientManagementRs.default.methods
        .getPropertyValue(clientData.value.name, 'document-management', 'companies-with-limited-document-languages-for-users')
        .then((response) => {
          companiesWithLimitedDocumentLanguagesForUsersPropertyValue = response.data;
        })
    );
    await Promise.all(promises);

    // Property example: Geminys[en-GB,es-ES,de-DE,fr-FR];CAF[es-ES,fr-FR];Client[fr-FR]
    if (companiesWithLimitedDocumentLanguagesForUsersPropertyValue?.length > 0) {
      var companiesWithLimitedDocumentLanguagesForUsersPropertySeparator = companiesWithLimitedDocumentLanguagesForUsersProperty.separator;
      var companiesWithLanguages = companiesWithLimitedDocumentLanguagesForUsersPropertyValue.split(companiesWithLimitedDocumentLanguagesForUsersPropertySeparator);
      if (companiesWithLanguages.length > 0) {
        // [company1[l1,l2], company2[l1], ...]
        for (var companyWithLanguages of companiesWithLanguages) {
          // If user is in company, limit the languages to the specified ones
          var company = companyWithLanguages.substring(0, companyWithLanguages.indexOf('['));
          // If company becomes a list, use something like this:
          // tokenParsed.company.includes(company)
          if (tokenParsed.company === company) {
            var languageArray = companyWithLanguages.substring(companyWithLanguages.indexOf('[') + 1, companyWithLanguages.lastIndexOf(']'));
            var languageList = languageArray.split(',');
            for (var language of languageList) {
              if (!documentLanguageIdsForUserInCompany.includes(language)) {
                documentLanguageIdsForUserInCompany.push(language);
              }
            }
          }
        }
      }
    }

    store.state.app.documentLanguageIdsForUserInCompany = documentLanguageIdsForUserInCompany;

    //var endTime = performance.now()
    //console.log(`Call took ${endTime - startTime} milliseconds`)

    vuetify.framework.theme.themes.light.primary = clientData.value.colorLightMode;
    vuetify.framework.theme.themes.dark.primary = clientData.value.colorDarkMode;
    // Theme colors are also defined here, but they do not change how the app looks:
    /*vuetify.preset.theme.themes.light.primary = clientData.value.colorLightMode
    vuetify.preset.theme.themes.dark.primary = clientData.value.colorDarkMode*/
    /*vuetify.userPreset.preset.theme.themes.light.primary = clientData.value.colorLightMode
    vuetify.userPreset.preset.theme.themes.dark.primary = clientData.value.colorDarkMode*/
    clientData.value.appLanguages.sort(function(a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    })
    clientData.value.documentLanguages.sort(function(a, b) {
      if (a.id > b.id) {
        return 1;
      }
      if (a.id < b.id) {
        return -1;
      }
      return 0;
    })
    var logoKeys = Object.keys(store.state.app.clientLogo.value)
    for (var i = 0; i < base64Array.length; i++) {
      var base64 = base64Array[i];
      if (base64.content) {
        var blob = base64ToBlob(
          base64.content,
          "image/".concat(clientData.value.logoType)
        );
        store.state.app.clientLogo.value = Object.assign(store.state.app.clientLogo.value, { [logoKeys[i]]: URL.createObjectURL(blob) })
      } else {
        store.state.app.clientLogo.value = Object.assign(store.state.app.clientLogo.value, { [logoKeys[i]]: '' })
      }
    }
  }
  catch (error) {
    console.log(error);
  }
}

let keycloak = KeyCloak(initOptions);

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      window.location.reload();
    } else {
      //console.log('Authenticated')

      Vue.prototype.$session.start();
      Vue.prototype.$session.set('keycloakToken', keycloak.token);
      Vue.prototype.$session.set('keycloakTokenParsed', keycloak.tokenParsed);

      store.state.app.appLanguages = ref([]);
      store.state.app.documentLanguages = ref([]);
      store.state.app.documentLanguagesFiltered = ref([]);

      store.state.app.clientLogo = ref({
        lightLogo: '',
        darkLogo: '',
        lightIsotype: '',
        darkIsotype: '',
      });

      loadClient().then(() => {
        // Fullfilled
    
        if (clientData.value.appLanguages) {
          var appLanguages = [];
          clientData.value.appLanguages.forEach((lang) => {
            loadLanguageAsync(lang.id);
            appLanguages.push({
              id: lang.id,
              locale: lang.code,
              localeUpperCase: lang.code.toUpperCase(),
              title: lang.fullName,
              color: lang.color,
              keycloakName: lang.keycloakName,
            });
          });
          store.state.app.appLanguages.value = appLanguages;
          setLanguage();
        }
        if (clientData.value.documentLanguages) {
          var documentLanguages = [];
          var documentLanguagesFiltered = [];
          var documentLanguageIdsForUserInCompany = store.state.app.documentLanguageIdsForUserInCompany;
          clientData.value.documentLanguages.forEach((lang) => {
            documentLanguages.push({
              id: lang.id,
              locale: lang.code,
              localeUpperCase: lang.code.toUpperCase(),
              title: lang.fullName,
              color: lang.color,
            });
            if (documentLanguageIdsForUserInCompany.length === 0 || documentLanguageIdsForUserInCompany.includes(lang.id)) {
              documentLanguagesFiltered.push({
                id: lang.id,
                locale: lang.code,
                localeUpperCase: lang.code.toUpperCase(),
                title: lang.fullName,
                color: lang.color,
              });
            }
          });
          store.state.app.documentLanguages.value = documentLanguages;
          store.state.app.documentLanguagesFiltered.value = documentLanguagesFiltered.length > 0
            ? documentLanguagesFiltered
            : documentLanguages;
        }
        
        try {
          var tokenParsed = Authentication.methods.getTokenParsed();
          if (tokenParsed && tokenParsed.language) {
            setLanguage(tokenParsed.language);
          }
        }
        catch (error) {
          console.log(error);
        }
    
        new Vue({
          router,
          store,
          i18n,
          vuetify,
          el: '#app',
          render: h => h(App, { props: { keycloak: keycloak } })
        });
      
      }, () => {
        // Rejected

        new Vue({
          router,
          store,
          i18n,
          vuetify,
          el: '#app',
          render: h => h(App, { props: { keycloak: keycloak } })
        });
      });
    }

    //Token Refresh
    setInterval(() => {
      keycloak.updateToken(300).then((refreshed) => {
        if (refreshed) {
          //console.log('Token refreshed');
          Vue.prototype.$session.set('keycloakToken', keycloak.token);
          Vue.prototype.$session.set('keycloakTokenParsed', keycloak.tokenParsed);
        } else {
          //console.log('Token not refreshed, valid for '.concat(Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000)).concat(' seconds'));
        }
      }).catch(() => {
        //console.log('Failed to refresh the token, or the session has expired');
      });
    }, 6000);

  }).catch(() => {
    //console.log('Authenticated Failed');
    window.location.reload();
  });
